@tailwind base;
@tailwind components;
@tailwind utilities;

.button {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
}

.inline-button {
  @apply bg-blue-500 text-white font-bold py-1 px-1 rounded;
}

.input-box {
  @apply w-full;
}

.row {
  @apply bg-cyan-700;
  @apply p-1;
  @apply border-spacing-1 border-cyan-950 border rounded-md p-1;
}

.row-label {
  @apply w-44;
}
.row-value {
  @apply bg-cyan-900;
  @apply border border-cyan-950 rounded-md p-1;
}

.type-fields {
  @apply ml-4;
}
